import React, { useEffect, useState } from "react";
import { Web3, net } from "web3";
import RouterAbi from "../../common/RouterAbi.json";
const web3 = new Web3("https://bsc-dataseed.binance.org/");

export default function Hero() {
  const [netPrice, setNetPrice] = useState(0);

  const getNetPrice = async () => {
    try {
      const contract = new web3.eth.Contract(RouterAbi, "0x10ED43C718714eb63d5aA57B78B54704E256024E");
      const amountsOut = await contract.methods.getAmountsOut("1000000000000000000", ["0x55d398326f99059fF775485246999027B3197955", "0x102054C91C93B0D30f982f28f224896D3d844b99"]).call()
      setNetPrice(Number(web3.utils.fromWei(amountsOut[1], "ether")).toFixed(4))
    } catch (err) {
      // console.log("error in getNetPrice : ",err);
    }
  }

  useEffect(() => {
    getNetPrice();
  }, [])
  return (
    <>
      <div
        className="fugu--hero-section"
      >
        <video muted autoPlay loop id="myVideo" className="video">
          <source src="assets/images/img-one/n1_hero_banner.mp4" type="video/mp4" />
        </video>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-9">
              <div className="fugu--hero-conten fugu--hero-content3">
                <h1
                  className="wow fadeInUpX text-white text-center fa-1"
                  data-wow-delay="0s"
                  style={{
                    visibility: "visible",
                    animationDelay: "0s",
                    animationName: "fadeInUpX",
                  }}
                >
                  Network1 chain for Network1 Creator, Network1 Founder, and Networker.
                </h1>
                <div className="">
                  <p
                    className="wow fadeInUpX text-white text-center fs-3 "
                    data-wow-delay="0.15s"
                    style={{
                      visibility: "visible",
                      animationDelay: "0.15s",
                      animationName: "fadeInUpX",
                    }}
                  >
                    Embark on your crypto-networking journey with ease and security. Join our platform for Crypto, Forex, RWA, Dex, Social Media, Browser, All in one place.
                  </p>
                  <div
                    className="fugu--btn-wrap fugu--hero-btn wow fadeInUpX text-center"
                    data-wow-delay="0.25s"
                    style={{
                      visibility: "visible",
                      animationDelay: "0.25s",
                      animationName: "fadeInUpX",
                    }}
                  >
                    <a className="fugu--btn bg-blue active rounded-pill" href="https://networkchain.gitbook.io/developer-documentation" target="_blank">
                      Documentation
                    </a>
                    <a className="fugu--btn bg-blue rounded-pill" href="https://networkchain.gitbook.io/networkchain/" target="_blank">
                      Whitepaper
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-lg-2 col-md-6">
              <div
                className="fugu--team-wrap wow fadeInUpX"
                data-wow-delay="0.50s"
                style={{
                  visibility: "visible",
                  animationDelay: "0.5s",
                  animationName: "fadeInUpX",
                }}
              >
                <div className="fugu--team-left">
                  <div className="fugu--team-data">
                    <h4>${netPrice ? netPrice : 0}</h4>
                    <small className="text-white">NET1 Coin Rate</small>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-2 col-md-6">
              <div
                className="fugu--team-wrap wow fadeInUpX"
                data-wow-delay="0s"
                style={{
                  visibility: "visible",
                  animationDelay: "0s",
                  animationName: "fadeInUpX",
                }}
              >
                <div className="fugu--team-left">
                  <div className="fugu--team-data">
                    <h4>-----</h4>
                    <p>Total transactions</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-2 col-md-6">
              <div
                className="fugu--team-wrap wow fadeInUpX"
                data-wow-delay="0.10s"
                style={{
                  visibility: "visible",
                  animationDelay: "0.1s",
                  animationName: "fadeInUpX",
                }}
              >
                <div className="fugu--team-left">
                  <div className="fugu--team-data">
                    <h4>----</h4>
                    <p>Total blocks</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-2 col-md-6">
              <div
                className="fugu--team-wrap wow fadeInUpX"
                data-wow-delay="0.20s"
                style={{
                  visibility: "visible",
                  animationDelay: "0.2s",
                  animationName: "fadeInUpX",
                }}
              >
                <div className="fugu--team-left">
                  <div className="fugu--team-data">
                    <h4>-----</h4>
                    <p>Wallet addresses</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-2 col-md-6">
              <div
                className="fugu--team-wrap wow fadeInUpX"
                data-wow-delay="0.30s"
                style={{
                  visibility: "visible",
                  animationDelay: "0.3s",
                  animationName: "fadeInUpX",
                }}
              >
                <div className="fugu--team-left">
                  <div className="fugu--team-data">
                    <h4>-----</h4>
                    <p>Latest gas fee</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-2 col-md-6">
              <div
                className="fugu--team-wrap wow fadeInUpX"
                data-wow-delay="0.40s"
                style={{
                  visibility: "visible",
                  animationDelay: "0.4s",
                  animationName: "fadeInUpX",
                }}
              >
                <div className="fugu--team-left">
                  <div className="fugu--team-data">
                    <h4>----</h4>
                    <p>Latest transaction</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
