import React from 'react'

export default function MineNetworFeture() {
    return (
        <>
            <div className="section bg-warning-200 fugu-section-padding3">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="py-4 text-start">
                                <h2>Features of Mine Your Network1</h2>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="box-style fugu-iconbox-wrap2 wow fadeInUpX" data-wow-delay="0s" style={{ visibility: 'visible', animationDelay: '0s', animationName: 'fadeInUpX' }}>
                                <div className="fugu-iconbox-icon2"><img src="assets/images/img-one/instant_airdrop.png" alt="" /></div>
                                <div className="fugu-iconbox-data2">
                                    <h4>Instant Airdrop</h4>
                                    <p>Upon logging in to the Mine Your Network1 platform, users instantly receive 1 NET1 coin as an airdrop, kickstarting their journey within the network.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="box-style fugu-iconbox-wrap2 wow fadeInUpX" data-wow-delay="0.20s" style={{ visibility: 'visible', animationDelay: '0.2s', animationName: 'fadeInUpX' }}>
                                <div className="fugu-iconbox-icon2"><img src="assets/images/img-one/limited_coin_distribution.png" alt="" /></div>
                                <div className="fugu-iconbox-data2">
                                    <h4>Limited Coin Distribution</h4>
                                    <p>Network1 Chain distributes a total of 500,000 NET1 coins through the Mine Your Network1 program, ensuring scarcity and value appreciation for participants.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="box-style fugu-iconbox-wrap2 wow fadeInUpX" data-wow-delay="0.30s" style={{ visibility: 'visible', animationDelay: '0.3s', animationName: 'fadeInUpX' }}>
                                <div className="fugu-iconbox-icon2"><img src="assets/images/img-one/affiliate_investment_rewards.png" alt="" /></div>
                                <div className="fugu-iconbox-data2">
                                    <h4>Affiliate Investment Rewards</h4>
                                    <p>Users who invest 10 USDT receive 30 NET1 coins as a reward, incentivizing participation and investment in the network.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="box-style fugu-iconbox-wrap2 wow fadeInUpX" data-wow-delay="0s" style={{ visibility: 'visible', animationDelay: '0s', animationName: 'fadeInUpX' }}>
                                <div className="fugu-iconbox-icon2"><img src="assets/images/img-one/daily_rewards_claim.png" alt="" /></div>
                                <div className="fugu-iconbox-data2">
                                    <h4>Daily Rewards Claim</h4>
                                    <p>Participants can claim their daily rewards, providing a consistent stream of income and encouraging active engagement with the platform.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="box-style fugu-iconbox-wrap2 wow fadeInUpX" data-wow-delay="0.20s" style={{ visibility: 'visible', animationDelay: '0.2s', animationName: 'fadeInUpX' }}>
                                <div className="fugu-iconbox-icon2"><img src="assets/images/img-one/level_income_gneration.png" alt="" /></div>
                                <div className="fugu-iconbox-data2">
                                    <h4>Level Income Generation</h4>
                                    <p>Users have the opportunity to earn level-based income by building and expanding their network. As they recruit new members and grow their team, they unlock additional income streams.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="box-style fugu-iconbox-wrap2 wow fadeInUpX" data-wow-delay="0.30s" style={{ visibility: 'visible', animationDelay: '0.3s', animationName: 'fadeInUpX' }}>
                                <div className="fugu-iconbox-icon2"><img src="assets/images/img-one/team_building_incentives.png" alt="" /></div>
                                <div className="fugu-iconbox-data2">
                                    <h4>Team Building Incentives</h4>
                                    <p>Users are encouraged to build and nurture their network teams, with rewards and incentives offered for achieving specific milestones and targets.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="box-style fugu-iconbox-wrap2 wow fadeInUpX" data-wow-delay="0s" style={{ visibility: 'visible', animationDelay: '0s', animationName: 'fadeInUpX' }}>
                                <div className="fugu-iconbox-icon2"><img src="assets/images/img-one/multi_level_rewards.png" alt="" /></div>
                                <div className="fugu-iconbox-data2">
                                    <h4>Multi-Level Rewards</h4>
                                    <p>Participants who successfully build their network to four levels, with a total of 280 miners, receive additional rewards from the blockchain. These rewards are distributed among the 2,000 founder members, further incentivizing network growth and participation.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="box-style fugu-iconbox-wrap2 wow fadeInUpX" data-wow-delay="0.20s" style={{ visibility: 'visible', animationDelay: '0.2s', animationName: 'fadeInUpX' }}>
                                <div className="fugu-iconbox-icon2"><img src="assets/images/img-one/founder_member_rewards.png" alt="" /></div>
                                <div className="fugu-iconbox-data2">
                                    <h4>Founder Member Rewards</h4>
                                    <p>Founder members of the Mine Your Network1 program are entitled to receive a share of the rewards generated by the blockchain. With a total distribution of 1,105 NET1 coins, founder members benefit from the success and growth of the network.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="box-style fugu-iconbox-wrap2 wow fadeInUpX" data-wow-delay="0.30s" style={{ visibility: 'visible', animationDelay: '0.3s', animationName: 'fadeInUpX' }}>
                                <div className="fugu-iconbox-data2">
                                    <p>Mine Your Network1 serves as a cornerstone of the Network1 Chain ecosystem, empowering users to actively engage with the platform, build their networks, and earn rewards while contributing to the growth and sustainability of the network. Through innovative features and incentives, Mine Your Network1 fosters a vibrant and thriving community of networkers within the Network1 Chain ecosystem.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
