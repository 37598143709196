import React from "react";

function ReadyToExplore() {
  return (
    <>
      <div
        className="fugu--hero-section3"
        style={{
          backgroundImage: `url('assets/images/img-one/ready_to_join_us_image.jpg')`,
          // backgroundRepeat: "no-repeat",
          // backgroundSize: "cover",
          // backgroundPosition: "50% 50%",
        }}
      >
        <div className="">
          <h2 className="text-center text-white">Ready to join us?</h2>

          <p className="text-size-large text-white text-center ">
            Join our vibrant community of developers & blockchain enthusiasts{" "}
            <br />
            who collectively work together to for the growth & expansion of
            Network1 ecosystem!
          </p>
        </div>
      </div>
      <div className="pt-5" style={{ paddingLeft: "13%", paddingRight: "15%" }}>
        <div className="row">
          <div className="col">
            <div className="explore_wrapper is-1">
              <p className="super-heading is-white">Network1 Ecosystem</p>
              <h2 className="heading-style-h2 text-white">20+ dApps</h2>
              <p className="text-size-medium text-color-white">
                Find latest & most used dApps, tools & other platforms within
                <br />
                the Network1 ecosystem.
              </p>

              <a className="button is-alternate w-button" href="">
                dApps
              </a>
            </div>
          </div>
          <div className="col">
            <div className="explore_wrapper">
              <div className="super-heading is">Network1 Community</div>
              <h2 className="heading-style-h2">10k+ Members</h2>
              <p className="text-size-medium">
                Join the community of over 10,000 developers, users &
                enthusiasts that put Network1 among top blockchain platforms.
              </p>
              <br />
              <a href="" className="arrow-button w-inline-block">
                Join Community
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    fill="currentColor"
                    class="bi bi-arrow-right"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"
                    />
                  </svg>
                </span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ReadyToExplore;
