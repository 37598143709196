import React, { useState } from "react";

export default function EcosystemApp() {
  const [showtab, setShowTab] = useState(0);
  return (
    <>
      <div
        className="fugu--inner-section dark-version bg-white"
        // style={{
        //   backgroundImage: `url('assets/images/img-one/n1_network-bg.png')`,
        //   backgroundRepeat: "no-repeat",
        //   backgroundSize: "cover",
        //   backgroundPosition: "50% 50%",
        // }}
      >
        <div className="container">
          <div className="fugu--breadcrumbs-section">
            <div className="fugu--breadcrumbs-data">
              <h1 className="span1">Ecosystem & Product</h1>
              <p className="text-black">
                Your source of market analysis, news, developments, and project
                reviews for the NET1 ecosystem. Discover and keep up to date
                with the latest Product.
              </p>
            </div>
          </div>
          {/* End breadcrumb */}
          <div className="fugu--blog-sidebar-section">
            <div className="row">
              <div className="col-lg-12 mb-5">
                <div
                  className="fugu--btn-wrap fugu--hero-btn wow fadeInUpX text-center"
                  data-wow-delay="0.25s"
                  style={{
                    visibility: "visible",
                    animationDelay: "0.25s",
                    animationName: "fadeInUpX",
                  }}
                >
                  <a
                    className={
                      showtab === 0
                        ? "fugu--btn bg-blue active rounded-pill"
                        : "fugu--btn bg-blue  rounded-pill"
                    }
                    onClick={() => setShowTab(0)}
                  >
                    All
                  </a>
                  <a
                    className={
                      showtab === 1
                        ? "fugu--btn bg-blue active rounded-pill"
                        : "fugu--btn bg-blue  rounded-pill"
                    }
                    onClick={() => setShowTab(1)}
                  >
                    CEX
                  </a>
                  <a
                    className={
                      showtab === 2
                        ? "fugu--btn bg-blue active rounded-pill"
                        : "fugu--btn bg-blue  rounded-pill"
                    }
                    onClick={() => setShowTab(2)}
                  >
                    Defi
                  </a>
                  <a
                    className={
                      showtab === 3
                        ? "fugu--btn bg-blue active rounded-pill"
                        : "fugu--btn bg-blue  rounded-pill"
                    }
                    onClick={() => setShowTab(3)}
                  >
                    SocialFi
                  </a>
                  <a
                    className={
                      showtab === 4
                        ? "fugu--btn bg-blue active rounded-pill"
                        : "fugu--btn bg-blue  rounded-pill"
                    }
                    onClick={() => setShowTab(4)}
                  >
                    Metaverse
                  </a>
                  <a
                    className={
                      showtab === 5
                        ? "fugu--btn bg-blue active rounded-pill"
                        : "fugu--btn bg-blue  rounded-pill"
                    }
                    onClick={() => setShowTab(5)}
                  >
                    Wallet
                  </a>
                  <a
                    className={
                      showtab === 6
                        ? "fugu--btn bg-blue active rounded-pill"
                        : "fugu--btn bg-blue  rounded-pill"
                    }
                    onClick={() => setShowTab(6)}
                  >
                    Infrastructure
                  </a>
                  <a
                    className={
                      showtab === 7
                        ? "fugu--btn bg-blue active rounded-pill"
                        : "fugu--btn bg-blue  rounded-pill"
                    }
                    onClick={() => setShowTab(7)}
                  >
                    GameFi
                  </a>
                  <a
                    className={
                      showtab === 8
                        ? "fugu--btn bg-blue active rounded-pill"
                        : "fugu--btn bg-blue  rounded-pill"
                    }
                    onClick={() => setShowTab(8)}
                  >
                    Bridge
                  </a>
                  <a
                    className={
                      showtab === 9
                        ? "fugu--btn bg-blue active rounded-pill"
                        : "fugu--btn bg-blue  rounded-pill"
                    }
                    onClick={() => setShowTab(9)}
                  >
                    DEX
                  </a>
                  <a
                    className={
                      showtab === 10
                        ? "fugu--btn bg-blue active rounded-pill"
                        : "fugu--btn bg-blue  rounded-pill"
                    }
                    onClick={() => setShowTab(10)}
                  >
                    NFT
                  </a>
                  <a
                    className={
                      showtab === 11
                        ? "fugu--btn bg-blue active rounded-pill"
                        : "fugu--btn bg-blue  rounded-pill"
                    }
                    onClick={() => setShowTab(11)}
                  >
                    Payment solution
                  </a>
                </div>
              </div>
              <div className="col-lg-12">
                <div className={showtab === 0 ? "row" : "d-none"}>
                  <div className="col-md-3">
                    <img src="assets/images/img-one/netex.svg" alt="" />
                  </div>
                  <div className="col-md-3">
                    <img src="assets/images/img-one/netfx.svg" alt="" />
                  </div>
                  <div className="col-md-3">
                    <img src="assets/images/img-one/netter.svg" alt="" />
                  </div>
                  <div className="col-md-3">
                    <img src="assets/images/img-one/netbox.svg" alt="" />
                  </div>
                  <div className="col-md-3">
                    <img
                      src="assets/images/img-one/mine_ur_network.svg"
                      alt=""
                    />
                  </div>
                  <div className="col-md-3">
                    <img src="assets/images/img-one/netmeet.svg" alt="" />
                  </div>
                  <div className="col-md-3">
                    <img src="assets/images/img-one/netpay.svg" alt="" />
                  </div>
                  <div className="col-md-3">
                    <img src="assets/images/img-one/netsoft.svg" alt="" />
                  </div>
                  <div className="col-md-3">
                    <img src="assets/images/img-one/netplay.svg" alt="" />
                  </div>
                </div>
                <div className={showtab === 1 ? "row" : "d-none"}>
                  <div className="col-md-3">
                    <img src="assets/images/img-one/netex.svg" alt="" />
                  </div>
                  <div className="col-md-3">
                    <img src="assets/images/img-one/netfx.svg" alt="" />
                  </div>
                </div>
                <div className={showtab === 2 ? "row" : "d-none"}>
                  <div className="col-md-3">
                    <img src="assets/images/img-one/netbox.svg" alt="" />
                  </div>
                  <div className="col-md-3">
                    <img
                      src="assets/images/img-one/mine_ur_network.svg"
                      alt=""
                    />
                  </div>
                </div>
                <div className={showtab === 3 ? "row" : "d-none"}>
                  <div className="col-md-3">
                    <img src="assets/images/img-one/netter.svg" alt="" />
                  </div>
                </div>
                <div className={showtab === 4 ? "row" : "d-none"}>
                  <div className="col-md-3">
                    <img src="assets/images/img-one/netmeet.svg" alt="" />
                  </div>
                </div>
                <div className={showtab === 5 ? "row" : "d-none"}>
                  <div className="col-md-3">
                    <img src="assets/images/img-one/netpay.svg" alt="" />
                  </div>
                </div>
                <div className={showtab === 6 ? "row" : "d-none"}>
                  <div className="col-md-3">
                    <img src="assets/images/img-one/netsoft.svg" alt="" />
                  </div>
                </div>
                <div className={showtab === 7 ? "row" : "d-none"}>
                  <div className="col-md-3">
                    <img src="assets/images/img-one/netplay.svg" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* End nexto single blog */}
        </div>
      </div>
    </>
  );
}
