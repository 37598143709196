import React from "react";

function SNARKtor() {
  return (
    <>
      <div className="container p-5">
        <div
          className="heading-style-h2 text-color-gray900 "
          style={{ textAlign: "center" }}
        >
          Proposing <span className="span1"> the native blockchain</span> for accelerating & expanding
          <span className="span1"> the Network1 Marketing</span> industry
        </div>
        <br />
        <br />
        <div className="row">
          <div className="col-xl-6">
            <p style={{ color: "#888cff" }}>Get to know </p>
            <h2>Network1</h2>
            <p>
              Network1 is designed to provide a robust, scalable, and efficient
              platform for network marketing operations. It recognizes the
              inherent complexities and limitations that network marketing firms
              encounter, including difficulties in global expansion, lack of
              transparency, and cumbersome payment processes.
            </p>
            <div className="divider"></div>
            <ul style={{ listStyle: "circle" }}>
              <li>
                <i class="fa fa-circle" aria-hidden="true"></i> High throughput
              </li>
              <li>
                <i class="fa fa-circle" aria-hidden="true"></i> Cost efficient
              </li>
              <li>
                <i class="fa fa-circle" aria-hidden="true"></i> Robust Security
              </li>
              <li>
                <i class="fa fa-circle" aria-hidden="true"></i> Liner Scalable
              </li>
            </ul>
            <br />
            <div className="button-group">
              <a className="button-Whitepaper">
                <button className="w-button " href="">
                  Read More
                </button>
              </a>
        
            </div>
            <br />
          </div>
          <div className="col-xl-6 d-flex justify-content-center align-items-centerol">
            <img
              style={{ height: "500px", width: "500px" }}
              src="assets/images/img-one/Network1.png"
            />
          </div>
        </div>
        <br />
        <br />
        <div className="row align-items-center">
          <div className="col-xl-7 ">
            <img
              style={{ height:"640px", width: "640px" }}
           src="assets/images/img-one/proof-of-network-pom.png"
            />
          </div>
          <div className="col-xl-5">
            <p className="text-color-zk text-style-allcaps">Get to know</p>
            <h2>Proof-of-Network (PoN)</h2>
            <p>
              Proof of Network (PoN) is an innovative consensus mechanism
              designed to secure and validate transactions on a blockchain
              network. Unlike traditional consensus mechanisms like Proof of
              Work (PoW) or Proof of Stake (PoS), which rely on computational
              power or token ownership, PoN leverages the concept of network
              participation and reputation to achieve consensus.
            </p>
            <div className="divider"></div>
            <ul>
              <li>
                <i class="fa fa-circle" aria-hidden="true"></i>
                Networking-focused
              </li>
              <li>
                <i class="fa fa-circle" aria-hidden="true"></i> Peer-to-peer
                Nodes
              </li>
              <li>
                <i class="fa fa-circle" aria-hidden="true"></i> Security of PoS
                Consensus
              </li>
            </ul>
            <br />
            <div className="button-group">
              <a className="button-Whitepaper">
                <button className="w-button " href="">
                Learn more
                </button>
              </a>
        
            </div>
            <br />
          </div>
        </div>
        <br />
        <br />
        <div className="row">
          <div className="col-xl-6">
            <p className="text-color-ev text-style-allcaps">
              Built with latest tech-stack
            </p>
            <h2>Network1 Infrastructure </h2>
            <p>
              Network1 hosts one of the most secure & reliable blockchain
              infrastructure that enables seamless transactions. It uses the
              latest tech-stack to offer developers a highly scalable, low-cost
              & adaptable blockchain platform.
            </p>
            <div className="divider"></div>
            <div className="_2-col_stat-grid">
              <div className="_2-col_stat-item">
                <h3 className="_2-col_stat-item">1K TPS</h3>
                <p className="p-grey">Hyper-Speed Transactions</p>
              </div>
              <div className="_2-col_stat-item">
                <h3 className="_2-col_stat-item">$0.01</h3>
                <p className="p-grey">Low Gas Fees</p>
              </div>
              <div className="_2-col_stat-item">
                <h3 className="_2-col_stat-item">1 Sec</h3>
                <p className="p-grey">Blocktime</p>
              </div>
              <div className="_2-col_stat-item">
                <h3 className="_2-col_stat-item">~10kb</h3>
                <p className="p-grey">Block size</p>
              </div>
            </div>
            <br />
            <div className="button-group">
              <a className="button-Whitepaper">
                <button className="w-button " href="">
                  Learn more
                </button>
              </a>
              <a className="arrow-button w-inline-block">
                Visit docs
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    fill="currentColor"
                    class="bi bi-arrow-right"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"
                    />
                  </svg>
                </span>
              </a>
            </div>
            <br />
          </div>
          <div className="col-xl-6 d-flex justify-content-center align-items-centerol">
            <img
              style={{ height: "640px", width: "600px" }}
               src="assets/images/img-one/network1-infrastructure.png"
            />
          </div>
        </div>
        <br />
        <br />
        <div className="row">
          <div className="col-xl-6 d-flex justify-content-center align-items-centerol">
            <img
                style={{ width: "100%" }}
           src="assets/images/img-one/proof-of-network.png"
            />
          </div>
          <div className="col-xl-6">
            <p className="text-color-zero text-style-allcaps">
              Start building dApps
            </p>
            <h2>Build on Network1</h2>
            <p>
              Network1 demonstrates an easy developer experience to build &
              launch various decentralized applications (dApps). Its versatility
              and applicability across various industries and business models
              through a wide range of use cases.
            </p>
            <br />
            <div className="button-group">
              <a className="button-Whitepaper">
                <button className="w-button " href="">
                  Build
                </button>
              </a>
              <a className="arrow-button w-inline-block">
                Docs
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    fill="currentColor"
                    class="bi bi-arrow-right"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"
                    />
                  </svg>
                </span>
              </a>
            </div>
            <br />
          </div>
        </div>
        <br />
        <br />
      </div>
    </>
  );
}

export default SNARKtor;
