import React from "react";

export default function WhyChoosNetter() {
  return (
    <>
      <div
        className="fugu--content-section bg-white pt-5"
        style={{
        //   backgroundImage: `url('assets/images/img-one/n1_network-bg.png')`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "50% 50%",
        }}
      >
        <div className="container">
          <div className="fugu--content-top">
            <div className="row">
              <div className="col-xl-6 d-flex justify-content-center align-items-center order-xl-2">
                <div className="fugu--content-thumb">
                  <img
                    className="wow fadeInUpX w-100"
                    data-wow-delay=".10s"
                    src="assets/images/img-one/why-choose-us.(2).png"
                    alt=""
                    style={{
                      visibility: "visible",
                      animationDelay: "0.1s",
                      animationName: "fadeInUpX",
                    }}
                  />
                </div>
              </div>
              <div className="col-xl-6">
                <div className="fugu--default-content">
                  <h2 className="text-black">Benefits of Netter</h2>
                  <div className="fugu--meta">
                    <ul>
                      <li className="text-black">
                        <img src="assets/images/svg2/check.svg" alt="" />
                        Professional Growth: Netter provides users with valuable
                        networking opportunities, industry insights, and
                        educational resources to support their professional
                        growth and development.
                      </li>
                      <li className="text-black">
                        <img src="assets/images/svg2/check.svg" alt="" />
                        Collaboration: By connecting users with like-minded
                        individuals and potential collaborators, Netter
                        facilitates collaboration and knowledge sharing within
                        the Network1 Chain community.
                      </li>
                      <li className="text-black">
                        <img src="assets/images/svg2/check.svg" alt="" />
                        Community Building: Netter fosters a sense of community
                        and belonging among its users, creating a supportive
                        environment where individuals can connect, engage, and
                        learn from each other.
                      </li>
                      <li className="text-black">
                        <img src="assets/images/svg2/check.svg" alt="" />
                        Visibility and Recognition: Netter provides users with a
                        platform to showcase their expertise, build their
                        personal brand, and gain visibility within the Network1
                        Chain community and beyond.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
