import React from 'react'

export default function NetMeetFeature() {
    return (
        <>
            <div className="fugu--content-section bg-white">
                <div className="container">
                    <div className="fugu--content-bottom">
                        <div className="row align-items-center">
                            <div className="col-xl-6 d-flex align-items-center">
                                <div className="fugu--content-thumb">
                                    <img className="wow fadeInLeft w-100" data-wow-delay=".10s" src="assets/images/img-one/Features-of-NetEx.png" alt="" style={{ visibility: 'visible', animationDelay: '0.1s', animationName: 'fadeInLeft' }} />
                                 </div>
                            </div>
                            <div className="col-xl-6">
                                <div className="fugu--default-content">
                                    <h2 className='text-black'>Features of NetMeet</h2>
                                    <div className="fugu--accordion-one accordion-two" id="accordionExample">
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="headingOne">
                                                <button className="accordion-button collapsed text-black" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                    Video Conferencing
                                                </button>
                                            </h2>
                                            <div id="collapseOne" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                                <div className="accordion-body text-black">
                                                    NetMeet provides high-quality video conferencing capabilities, allowing users to host or join meetings with ease. It supports multiple participants, enabling team discussions, presentations, and brainstorming sessions.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="headingTwo">
                                                <button className="accordion-button collapsed text-black" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                    Screen Sharing
                                                </button>
                                            </h2>
                                            <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                                <div className="accordion-body text-black">
                                                    Users can share their screens during meetings, making it convenient to showcase presentations, documents, or other materials. This feature enhances collaboration and facilitates effective communication among participants.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="headingThree">
                                                <button className="accordion-button collapsed text-black" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                    Chat Functionality
                                                </button>
                                            </h2>
                                            <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                                <div className="accordion-body text-black">
                                                    NetMeet includes a chat feature that enables participants to communicate via text during meetings. This feature allows for real-time messaging, questions, and discussions parallel to the main conversation, enhancing engagement and interaction.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="headingFour">
                                                <button className="accordion-button collapsed text-black" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                                    Meeting Recording
                                                </button>
                                            </h2>
                                            <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                                                <div className="accordion-body text-black">
                                                    NetMeet allows users to record meetings for future reference or sharing with absent participants. This feature ensures that important discussions and decisions are captured, providing a valuable resource for review and follow-up.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="headingFive">
                                                <button className="accordion-button collapsed text-black" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                                    Calendar Integration
                                                </button>
                                            </h2>
                                            <div id="collapseFive" className="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExample">
                                                <div className="accordion-body text-black">
                                                    NetMeet seamlessly integrates with users' calendars, making it easy to schedule and manage meetings. Integration with popular calendar applications ensures that users stay organized and informed about upcoming meetings and events.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="headingSix">
                                                <button className="accordion-button collapsed text-black" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                                                    Security and Privacy
                                                </button>
                                            </h2>
                                            <div id="collapseSix" className="accordion-collapse collapse" aria-labelledby="headingSix" data-bs-parent="#accordionExample">
                                                <div className="accordion-body text-black">
                                                    NetMeet prioritizes security and privacy, employing robust encryption protocols to safeguard sensitive information shared during meetings. Users can conduct meetings with confidence, knowing that their data is protected from unauthorized access or interception.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
