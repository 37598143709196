import React from 'react'
import Header from '../Comman/Header'
import Footer from '../Comman/Footer'
import NetExHero from './NetExHero'
import WhyChoosNetEx from './WhyChoosNetEx'
import NetExFeature from './NetExFeature'
import TelosFooter from '../Telos_net/TelosFooter'

export default function NetEx() {
    return (
        <>
            <Header />
            <NetExHero />
            <NetExFeature /> 
            <WhyChoosNetEx />
            <TelosFooter/>
            {/* <Footer /> */}
        </>
    )
}
