import React from "react";

export default function NetExHero() {
  return (
    <>
      <div
        className="fugu--hero-section bg-white"
        style={{
          // backgroundImage: `url('assets/images/img-one/n1_network-bg.png')`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "50% 50%",
        }}
      >
        <div id="fugu--counter" />
        <div className="container">
          <div className="row">
            <div className="col-lg-7">
              <div className="fugu--hero-content">
                <h1 className="wow fadeInUpX  text-black" data-wow-delay="0s">
                  Your Gateway to the Crypto World
                </h1>
                <p className="wow fadeInUpX text-black" data-wow-delay="0.10s">
                  Net1Ex is a cutting-edge cryptocurrency exchange platform
                  designed to provide users with a seamless and secure trading
                  experience. Whether you're a seasoned trader or new to the
                  world of cryptocurrencies, Net1Ex offers a user-friendly
                  interface, advanced trading tools, and a wide range of digital
                  assets to suit your needs.
                </p>
                <div
                  className="fugu--btn-wrap fugu--hero-btn wow fadeInUpX"
                  data-wow-delay="0.20s"
                >
                  <a className="fugu--btn bg-gray active" href="/coming-soon">
                    Visit
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-5">
              <img
                src="assets/images/img-one/Net-Ex-Phn.png"
                className="w-100"
                alt=""
              />
            </div>
          </div>
          {/* <div className="fugu--hero-shape2" /> */}
        </div>
      </div>
    </>
  );
}
