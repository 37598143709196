import React from "react";

export default function NetPayFeature() {
  return (
    <>
      <div className="fugu--content-section bg-white">
        <div className="container">
          <div className="fugu--content-bottom">
            <div className="row align-items-center">
              <div className="col-xl-6 d-flex align-items-center">
                <div className="fugu--content-thumb">
                  <img
                    className="wow fadeInLeft"
                    data-wow-delay=".10s"
                    src="assets/images/img-one/Features-of-NetEx.png"
                    alt=""
                    style={{
                      visibility: "visible",
                      animationDelay: "0.1s",
                      animationName: "fadeInLeft",
                    }}
                  />
                </div>
              </div>
              <div className="col-xl-6">
                <div className="fugu--default-content">
                  <h2 className="text-black">Features of NetPay</h2>
                  <div
                    className="fugu--accordion-one accordion-two"
                    id="accordionExample"
                  >
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingOne">
                        <button
                          className="accordion-button collapsed text-black"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseOne"
                          aria-expanded="true"
                          aria-controls="collapseOne"
                        >
                          Cutting-Edge Solutions
                        </button>
                      </h2>
                      <div
                        id="collapseOne"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingOne"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body text-black">
                          NetSoft offers cutting-edge technological solutions
                          tailored to meet the diverse needs of businesses and
                          organizations. From software development to digital
                          transformation services, NetSoft delivers innovative
                          solutions that drive growth and efficiency.
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingTwo">
                        <button
                          className="accordion-button collapsed text-black"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseTwo"
                          aria-expanded="false"
                          aria-controls="collapseTwo"
                        >
                          Custom Development
                        </button>
                      </h2>
                      <div
                        id="collapseTwo"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingTwo"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body text-black">
                          Leverage NetSoft's expertise in custom software
                          development to create tailored solutions that align
                          with your unique business requirements. Whether you
                          need a web application, mobile app, or enterprise
                          software, NetSoft's team of experienced developers can
                          bring your vision to life.
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingThree">
                        <button
                          className="accordion-button collapsed text-black"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseThree"
                          aria-expanded="false"
                          aria-controls="collapseThree"
                        >
                          Tech Stack Expertise
                        </button>
                      </h2>
                      <div
                        id="collapseThree"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingThree"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body text-black">
                          Benefit from NetSoft's proficiency in a wide range of
                          programming languages, frameworks, and technologies.
                          Whether it's Java, Python, JavaScript, or blockchain
                          development, NetSoft's developers possess the skills
                          and expertise to deliver high-quality solutions using
                          the latest tech stack.
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingFour">
                        <button
                          className="accordion-button collapsed text-black"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseFour"
                          aria-expanded="false"
                          aria-controls="collapseFour"
                        >
                          Scalability and Flexibility
                        </button>
                      </h2>
                      <div
                        id="collapseFour"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingFour"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body text-black">
                          NetSoft's solutions are designed to be scalable and
                          flexible, allowing businesses to adapt and grow in a
                          rapidly changing market environment. Whether you're a
                          startup or a large enterprise, NetSoft's solutions can
                          scale to accommodate your evolving needs and
                          requirements.
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingFive">
                        <button
                          className="accordion-button collapsed text-black"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseFive"
                          aria-expanded="false"
                          aria-controls="collapseFive"
                        >
                          Agile Development Methodology:
                        </button>
                      </h2>
                      <div
                        id="collapseFive"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingFive"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body text-black">
                          NetSoft follows agile development methodologies to
                          ensure rapid and iterative delivery of software
                          solutions. By breaking down projects into smaller,
                          manageable tasks and prioritizing customer feedback,
                          NetSoft ensures that projects are completed on time
                          and within budget.
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingFive">
                        <button
                          className="accordion-button collapsed text-black"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseFive"
                          aria-expanded="false"
                          aria-controls="collapseFive"
                        >
                          Quality Assurance
                        </button>
                      </h2>
                      <div
                        id="collapseFive"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingFive"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body text-black">
                          Quality is paramount at NetSoft, and stringent quality
                          assurance processes are in place to ensure that all
                          deliverables meet the highest standards of excellence.
                          From code reviews to automated testing, NetSoft
                          employs best practices to guarantee the reliability,
                          performance, and security of its solutions.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
