import React from 'react'

export default function MineUrNetwork() {
  return (
    <>
      <div className="fugu--content-section bg-white pt-5">
        <div className="container">
          <div className="fugu--content-top">
            <div className="row align-items-center">
              <div className="col-xl-6 d-flex justify-content-center align-items-center order-xl-2">
                <img src="assets/images/img-one/mine_ur_network.svg" className='w-100' alt="" />
              </div>
              <div className="col-xl-6">
                <div className="fugu--default-content">
                  <h2 className='text-color-zk text-style-allcaps'>Mine ur Network1</h2>
                  <p className='text-black'>Mine-ur-Network1 is a pioneering affiliate-based program offered by Network1 Chain. Users can instantly join and receive 1 NET1 coin as an airdrop upon login. With a total distribution of 500,000 NET1 coins, participants can earn up to 30 coins by investing 10 USDT. By claiming daily rewards and building their teams, users can unlock lucrative level-based rewards, culminating in substantial miner rewards from the blockchain for achieving up to 280 miners across four levels.</p>
          
                </div>
                <div className="button-Whitepaper-w30">
                  <a className="w-button" href="/mine-ur-network">Learn more</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
