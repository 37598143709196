import React from 'react'
import Header from '../Comman/Header'
import Footer from '../Comman/Footer'
import EcosystemHero from './EcosystemHero'
import EcosystemApp from './EcosystemApp'
import TelosFooter from '../Telos_net/TelosFooter'

export default function Ecosystem() {
    return (
        <>
            <Header />
            {/* <EcosystemHero /> */}
            <EcosystemApp/>
            <TelosFooter/>
            {/* <Footer /> */}
        </>
    )
}
