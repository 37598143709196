import React from "react";

export default function NetterHero() {
  return (
    <>
      <div
        className="fugu--hero-section bg-white"
        style={{
        //   backgroundImage: `url('assets/images/img-one/n1_network-bg.png')`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "50% 50%",
        }}
      >
        <div id="fugu--counter" />
        <div className="container">
          <div className="row">
            <div className="col-lg-7">
              <div className="fugu--hero-content">
                <h1 className="wow fadeInUpX  text-black" data-wow-delay="0s">
                  Empowering Networking Enthusiasts
                </h1>
                <p className="wow fadeInUpX text-black" data-wow-delay="0.10s">
                  Netter is a dynamic social media platform tailored
                  specifically for networking enthusiasts within the Network1
                  Chain ecosystem. It serves as a vibrant hub where users can
                  connect, engage, and collaborate with like-minded individuals
                  from around the world. With its innovative features and
                  user-friendly interface, Netter is revolutionizing the way
                  networkers interact and share valuable insights.
                </p>
                <div
                  className="fugu--btn-wrap fugu--hero-btn wow fadeInUpX"
                  data-wow-delay="0.20s"
                >
                  <a className="fugu--btn bg-gray active" href="/coming-soon">
                    Visit
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-5">
              <img
                src="assets/images/img-one/Netter-phn.png"
                className="w-100"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
