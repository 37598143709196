import logo from "./logo.svg";
import "./App.css";
import Index from "./Components/Home/Index";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Career from "./Components/Career/Career";
import Developer from "./Components/Developer/Developer";
import Grants from "./Components/Grants/Grants";
import Decentralized from "./Components/Decentralized/Decentralized";
import Company from "./Components/Company/Company";
import Ecosystem from "./Components/Ecosystem/Ecosystem";
import CEG from "./Components/CEG/CEG";
import Platform from "./Components/Platform/Platform";
import Community from "./Components/Community/Community";
import DeveloperResourcers from "./Components/DeveloperResoures/DeveloperResourcers";
import Product from "./Components/Product/Product";
import NetEx from "./Components/NetEx/NetEx";
import NetFx from "./Components/NetFX/NetFx";
import NetMeet from "./Components/NetMeet/NetMeet";
import NetPay from "./Components/NetPay/NetPay";
import NetPlay from "./Components/NetPlay/NetPlay";
import Netsoft from "./Components/Netsoft/Netsoft";
import Netter from "./Components/Netter/Netter";
import NetworkChain from "./Components/NetworkChain/NetworkChain";
import Nbox from "./Components/Nbox/Nbox";
import MineNetwork from "./Components/MineNetwork/MineNetwork";
import ComingSoon from "./Components/Comman/ComingSoon";
import Research from "./Components/Research/Research";
import QA from "./Components/QA/QA";
import PrivacyPolice from "./Components/PrivacyPolice";
import TermsCondition from "./Components/TermsCondition";
import PrivacyPoliceMobile from "./Components/PrivacyPoliceMobile";
import TermsConditionMobile from "./Components/TermsConditionMobile";
import Stablecoin from "./Components/Stablecoin/Stablecoin";
function App() {
  return (
    <>
      {/* <Index /> */}
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Index />} />
          <Route path="/career" element={<Career />} />
          <Route path="/build" element={<Developer />} />
          <Route path="/network-studio" element={<Grants />} />
          <Route path="/validator" element={<Decentralized />} />
          <Route path="/token" element={<Company />} />
          <Route path="/ecosystem" element={<Ecosystem />} />
          <Route path="/governance" element={<CEG />} />
          <Route path="/platform" element={<Platform />} />
          <Route path="/community" element={<Community />} />
          <Route path="/developer" element={<DeveloperResourcers />} />
          <Route path="/product" element={<Product />} />
          <Route path="/net-ex" element={<NetEx />} />
          <Route path="/net-fx" element={<NetFx />} />
          <Route path="/net-meet" element={<NetMeet />} />
          <Route path="/net-pay" element={<NetPay />} />
          <Route path="/net-play" element={<NetPlay />} />
          <Route path="/net-soft" element={<Netsoft />} />
          <Route path="/netter" element={<Netter />} />
          <Route path="/networkchain" element={<NetworkChain />} />
          <Route path="/net-box" element={<Nbox />} />
          <Route path="/mine-ur-network" element={<MineNetwork />} />
          <Route path="/coming-soon" element={<ComingSoon />} />
          <Route path="/exploration" element={<Research />} />
          <Route path="/q-and-a" element={<QA />} />
          <Route path="/privacy-policy" element={<PrivacyPolice/>} />
          <Route path="/app-privacy-policy" element={<PrivacyPoliceMobile/>} />
          <Route path="/terms-of-services" element={<TermsCondition/>} />
          <Route path="/app-terms-of-services" element={<TermsConditionMobile/>} />
          <Route path="/Stablecoin" element={<Stablecoin/>}/>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
