import React from "react";

function TelosFooter() {
  return (
    <>
      <footer className="footer-section">
        <div className="p-5" style={{ marginLeft: "5%", marginRight: "5%" }}>
          <div className="row justify-content-between align-content-center">
            <div className="col-lg-2 col-md-4 col-sm-4">
              <div className="fugu-footer-menu">
                <div className="fugu-textarea">
                  <div className="fugu-footer-logo">
                    <a href="/">
                      <img
                        src="assets/images/img-one/N1_logo.png"
                        style={{ width: "220px" }}
                        alt=""
                        className="light-version-logo"
                      />
                    </a>
                  </div>
                  <div className=" d-flex  gap-1">
                    <a className="text-black" href="">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="25"
                        height="25"
                        fill="currentColor"
                        class="bi bi-discord"
                        viewBox="0 0 16 16"
                      >
                        <path d="M13.545 2.907a13.2 13.2 0 0 0-3.257-1.011.05.05 0 0 0-.052.025c-.141.25-.297.577-.406.833a12.2 12.2 0 0 0-3.658 0 8 8 0 0 0-.412-.833.05.05 0 0 0-.052-.025c-1.125.194-2.22.534-3.257 1.011a.04.04 0 0 0-.021.018C.356 6.024-.213 9.047.066 12.032q.003.022.021.037a13.3 13.3 0 0 0 3.995 2.02.05.05 0 0 0 .056-.019q.463-.63.818-1.329a.05.05 0 0 0-.01-.059l-.018-.011a9 9 0 0 1-1.248-.595.05.05 0 0 1-.02-.066l.015-.019q.127-.095.248-.195a.05.05 0 0 1 .051-.007c2.619 1.196 5.454 1.196 8.041 0a.05.05 0 0 1 .053.007q.121.1.248.195a.05.05 0 0 1-.004.085 8 8 0 0 1-1.249.594.05.05 0 0 0-.03.03.05.05 0 0 0 .003.041c.24.465.515.909.817 1.329a.05.05 0 0 0 .056.019 13.2 13.2 0 0 0 4.001-2.02.05.05 0 0 0 .021-.037c.334-3.451-.559-6.449-2.366-9.106a.03.03 0 0 0-.02-.019m-8.198 7.307c-.789 0-1.438-.724-1.438-1.612s.637-1.613 1.438-1.613c.807 0 1.45.73 1.438 1.613 0 .888-.637 1.612-1.438 1.612m5.316 0c-.788 0-1.438-.724-1.438-1.612s.637-1.613 1.438-1.613c.807 0 1.451.73 1.438 1.613 0 .888-.631 1.612-1.438 1.612" />
                      </svg>
                    </a>
                    &nbsp;&nbsp;
                    <a className="text-black" href="">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="25"
                        height="25"
                        fill="currentColor"
                        class="bi bi-telegram"
                        viewBox="0 0 16 16"
                      >
                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8.287 5.906q-1.168.486-4.666 2.01-.567.225-.595.442c-.03.243.275.339.69.47l.175.055c.408.133.958.288 1.243.294q.39.01.868-.32 3.269-2.206 3.374-2.23c.05-.012.12-.026.166.016s.042.12.037.141c-.03.129-1.227 1.241-1.846 1.817-.193.18-.33.307-.358.336a8 8 0 0 1-.188.186c-.38.366-.664.64.015 1.088.327.216.589.393.85.571.284.194.568.387.936.629q.14.092.27.187c.331.236.63.448.997.414.214-.02.435-.22.547-.82.265-1.417.786-4.486.906-5.751a1.4 1.4 0 0 0-.013-.315.34.34 0 0 0-.114-.217.53.53 0 0 0-.31-.093c-.3.005-.763.166-2.984 1.09" />
                      </svg>
                    </a>
                    &nbsp;&nbsp;
                    <a className="text-black" href="">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="25"
                        height="25"
                        fill="currentColor"
                        class="bi bi-youtube"
                        viewBox="0 0 16 16"
                      >
                        <path d="M8.051 1.999h.089c.822.003 4.987.033 6.11.335a2.01 2.01 0 0 1 1.415 1.42c.101.38.172.883.22 1.402l.01.104.022.26.008.104c.065.914.073 1.77.074 1.957v.075c-.001.194-.01 1.108-.082 2.06l-.008.105-.009.104c-.05.572-.124 1.14-.235 1.558a2.01 2.01 0 0 1-1.415 1.42c-1.16.312-5.569.334-6.18.335h-.142c-.309 0-1.587-.006-2.927-.052l-.17-.006-.087-.004-.171-.007-.171-.007c-1.11-.049-2.167-.128-2.654-.26a2.01 2.01 0 0 1-1.415-1.419c-.111-.417-.185-.986-.235-1.558L.09 9.82l-.008-.104A31 31 0 0 1 0 7.68v-.123c.002-.215.01-.958.064-1.778l.007-.103.003-.052.008-.104.022-.26.01-.104c.048-.519.119-1.023.22-1.402a2.01 2.01 0 0 1 1.415-1.42c.487-.13 1.544-.21 2.654-.26l.17-.007.172-.006.086-.003.171-.007A100 100 0 0 1 7.858 2zM6.4 5.209v4.818l4.157-2.408z" />
                      </svg>
                    </a>
                    &nbsp;&nbsp;
                    <a className="text-black" href="">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="25"
                        height="25"
                        fill="currentColor"
                        class="bi bi-twitter-x"
                        viewBox="0 0 16 16"
                      >
                        <path d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865z" />
                      </svg>
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-2  col-md-4 col-sm-4">
              <div className="menu ">
                <ul>
                  <li>
                    <h5 className="fs-6 text-black">Quick Links</h5>
                  </li>
                  <li>
                    <a href="https://network1.gitbook.io/network1-whitepaper" className="fs-6 text-black">
                    Whitepaper
                    </a>
                  </li>
                  <li>
                    <a
                      className="text-black"
                      href="https://networkchain.gitbook.io/networkchain"
                      target="_blank"
                    >
                      Docs
                    </a>
                  </li>
                  <li>
                    <a href="https://net1scan.com/" target="_blank" className="fs-6 text-black">
                    Explorer
                    </a>
                  </li>
                  <li>
                    <a href="https://net1bridge.com/" target="_blank" className="fs-6 text-black">
                    Bridge
                    </a>
                  </li>
                  <li>
                    <a href="https://net1ex.com/" target="_blank" className="fs-6 text-black">
                    Buy NET1
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-2 col-md-4 col-sm-4">
              <div className="fugu-custom-margin">
                <ul>
                  <li>
                    <h5 className="fs-6 text-black">Explore</h5>
                  </li>
                  <li>
                    <a href="/ecosystem" className="fs-6 text-black">
                      Ecosystem
                    </a>
                  </li>
                  <li>
                    <a href="https://network1.gitbook.io/network1-whitepaper/network1-chain/roadmap" target="_blank" className="fs-6 text-black">
                    Roadmap
                    </a>
                  </li>
                  <li>
                    <a href="/governance" className="fs-6 text-black">
                    Governance
                    </a>
                  </li>
                  <li>
                    <a href="/developer" className="fs-6 text-black">
                    Developers
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-2 col-md-4 col-sm-4">
              <div className="fugu-custom-margin">
                <ul>
                  <li>
                    <h5 className="fs-6 text-black">Learn</h5>
                  </li>
                  <li>
                    <a href="https://network1.gitbook.io/documentation/core-concepts/proof-of-network-pon-consensus" target="_blank" className="fs-6 text-black">
                    Proof-of-Network
                    </a>
                  </li>
                  <li>
                    <a href="https://network1.gitbook.io/network1-whitepaper/network1-chain/net1-coin" target="_blank" className="fs-6 text-black">
                    NET1 Coin
                    </a>
                  </li>
                  <li>
                    <a href="https://network1.gitbook.io/network1-whitepaper/network1-chain/introduction-to-network1" target="_blank" className="fs-6 text-black">
                    Chain Infrastructure
                    </a>
                  </li>
                  <li>
                    <a href="https://network1.gitbook.io/documentation/core-concepts/network1-nodes" target="_blank" className="fs-6 text-black">
                    Network1 Nodes
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-2 col-md-4 col-sm-4">
              <div className="fugu-custom-margin">
                <ul>
                  <li>
                    <h5 className="fs-6 text-black">Company</h5>
                  </li>
                  <li>
                    <a href="https://network1.gitbook.io/documentation/core-concepts/network1" target="_blank" className="fs-6 text-black">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-code-square"
                        viewBox="0 0 16 16"
                      >
                        <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z" />
                        <path d="M6.854 4.646a.5.5 0 0 1 0 .708L4.207 8l2.647 2.646a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 0 1 .708 0m2.292 0a.5.5 0 0 0 0 .708L11.793 8l-2.647 2.646a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708 0" />
                      </svg>
                      &nbsp; About
                    </a>
                  </li>
                  <li>
                  <a href="https://network1.gitbook.io/documentation/core-concepts/network1" target="_blank" className="fs-6 text-black">

                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-github"
                        viewBox="0 0 16 16"
                      >
                        <path d="M8 0C3.58 0 0 3.58 0 8c0 3.54 2.29 6.53 5.47 7.59.4.07.55-.17.55-.38 0-.19-.01-.82-.01-1.49-2.01.37-2.53-.49-2.69-.94-.09-.23-.48-.94-.82-1.13-.28-.15-.68-.52-.01-.53.63-.01 1.08.58 1.23.82.72 1.21 1.87.87 2.33.66.07-.52.28-.87.51-1.07-1.78-.2-3.64-.89-3.64-3.95 0-.87.31-1.59.82-2.15-.08-.2-.36-1.02.08-2.12 0 0 .67-.21 2.2.82.64-.18 1.32-.27 2-.27s1.36.09 2 .27c1.53-1.04 2.2-.82 2.2-.82.44 1.1.16 1.92.08 2.12.51.56.82 1.27.82 2.15 0 3.07-1.87 3.75-3.65 3.95.29.25.54.73.54 1.48 0 1.07-.01 1.93-.01 2.2 0 .21.15.46.55.38A8.01 8.01 0 0 0 16 8c0-4.42-3.58-8-8-8" />
                      </svg>
                      &nbsp; Brand Assets
                    </a>
                  </li>
                  <li>
                  <a href="https://network1.gitbook.io/documentation/company/terms-of-use" target="_blank" className="fs-6 text-black">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-file-earmark-text"
                        viewBox="0 0 16 16"
                      >
                        <path d="M5.5 7a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1zM5 9.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5m0 2a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5" />
                        <path d="M9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.5zm0 1v2A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1z" />
                      </svg>
                      &nbsp; Terms of Use
                    </a>
                  </li>
                  <li>
                    <a href="https://network1.gitbook.io/documentation/company/privacy-policy" target="_blank" className="fs-6 text-black">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-github"
                        viewBox="0 0 16 16"
                      >
                        <path d="M8 0C3.58 0 0 3.58 0 8c0 3.54 2.29 6.53 5.47 7.59.4.07.55-.17.55-.38 0-.19-.01-.82-.01-1.49-2.01.37-2.53-.49-2.69-.94-.09-.23-.48-.94-.82-1.13-.28-.15-.68-.52-.01-.53.63-.01 1.08.58 1.23.82.72 1.21 1.87.87 2.33.66.07-.52.28-.87.51-1.07-1.78-.2-3.64-.89-3.64-3.95 0-.87.31-1.59.82-2.15-.08-.2-.36-1.02.08-2.12 0 0 .67-.21 2.2.82.64-.18 1.32-.27 2-.27s1.36.09 2 .27c1.53-1.04 2.2-.82 2.2-.82.44 1.1.16 1.92.08 2.12.51.56.82 1.27.82 2.15 0 3.07-1.87 3.75-3.65 3.95.29.25.54.73.54 1.48 0 1.07-.01 1.93-.01 2.2 0 .21.15.46.55.38A8.01 8.01 0 0 0 16 8c0-4.42-3.58-8-8-8" />
                      </svg>
                      &nbsp; Privacy Policy
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-2 col-md-4 col-sm-4">
              <div className="fugu-footer-menu fugu-custom-margin">
                <h4 className="">Download App</h4>
                <ul className="justify-content-start">
                  <li>
                    <a href="/coming-soon">
                      <img
                        src="./assets/images/app-store.png"
                        width="150"
                        className="border rounded m-1"
                        alt=""
                      />
                    </a>
                  </li>
                  <li>
                    <a href="https://drive.google.com/file/d/1kHWR2dZIgMN8uYmIWMNNTNKoLDLevfRN/view?usp=sharing"
                    target="_blank">
                      <img
                        src="./assets/images/play-store.png"
                        width="150"
                        className="border rounded m-1"
                        alt=""
                      />
                    </a>
                  </li>
                </ul>
               
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

export default TelosFooter;
