import React from "react";

export default function GetStarted() {
  return (
    <>
      {/* <div
                className="fugu--hero-section3"
                style={{ backgroundImage: `url('assets/images/img-one/n1_network-bg.png')`, backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: "50% 50%" }}
            >
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-9">
                            <div
                                className="video-text"
                                style={{
                                    visibility: "visible",
                                    animationName: "fadeInUpX",
                                    height: "500px",
                                }}
                            >
                                <div className="pt-5">
                                    <div className="fugu--hero-conten fugu--hero-content3">
                                        <h1
                                            className="wow fadeInUpX text-white"
                                            data-wow-delay="0s"
                                            style={{
                                                visibility: "visible",
                                                animationDelay: "0s",
                                                animationName: "fadeInUpX",
                                            }}
                                        >
                                            Welcome
                                        </h1>
                                    </div>
                                    <p
                                        className="wow fadeInUpX text-white text-start fs-3"
                                        data-wow-delay="0.15s"
                                        style={{
                                            visibility: "visible",
                                            animationDelay: "0.15s",
                                            animationName: "fadeInUpX",
                                        }}
                                    >
                                        Innovative Solutions for Decentralized <br /> Networking: Explore Our Platform.
                                    </p>
                                    <div
                                        className="fugu--btn-wrap fugu--hero-btn wow fadeInUpX"
                                        data-wow-delay="0.25s"
                                        style={{
                                            visibility: "visible",
                                            animationDelay: "0.25s",
                                            animationName: "fadeInUpX",
                                        }}
                                    >
                                        <a className="fugu--btn bg-blue active" href="/mine-ur-network" target='_blank'>
                                            Mine-ur-Network1
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <video muted autoPlay loop id="myVideo" className="video-2">
                                <source src="assets/images/img-one/n1_hero_banner.mp4" type="video/mp4" />
                            </video>
                        </div>
                    </div>
                </div>
            </div> */}
      <div className="container py-5">
        <div className="row">
          <div className="col-12">
            <div className="padding-global ">
              <div className="container-large cta_zero">
                {/* <div className='cta_background_items'>
                            <img src='https://cdn.prod.website-files.com/650f276b38fe3eaebe9f805a/6687d16aac4da95f45a00855_zero-image-4.webp' loading="lazy" className='background_image-1'/>
                            <img src='https://cdn.prod.website-files.com/650f276b38fe3eaebe9f805a/6687d16a96d1c22bead1bcb0_zero-image-2.webp' loading="lazy" className='background_image-2'/>
                            <img src='https://cdn.prod.website-files.com/650f276b38fe3eaebe9f805a/6687d16a0c895bf1ed9cbd99_zero-image-3.webp' loading="lazy" className='background_image-3'/>
                        </div> */}
                <div className="padding-section-small p-3">
                  <div className="pt-5">
                    <div
                      className="fugu--hero-conten fugu--hero-content3"
                      style={{ textAlign: "center" }}
                    >
                      <h1
                        className="wow fadeInUpX text-white"
                        data-wow-delay="0s"
                        style={{
                          visibility: "visible",
                          animationDelay: "0s",
                          animationName: "fadeInUpX",
                        }}
                      >
                        Start Developing 
                      </h1>
                    </div>
                    <p
                      className="wow fadeInUpX text-white text-center fs-3"
                      data-wow-delay="0.15s"
                      style={{
                        visibility: "visible",
                        animationDelay: "0.15s",
                        animationName: "fadeInUpX",
                      }}
                    >
                      Learn everything you need to know to <br /> get started as Network1 developer.
                    </p>
                    <div
                      className="fugu--btn-wrap fugu--hero-btn wow fadeInUpX"
                      data-wow-delay="0.25s"
                      style={{
                        visibility: "visible",
                        animationDelay: "0.25s",
                        animationName: "fadeInUpX",
                        textAlign: "center",
                      }}
                    >
                      <a
                        className="fugu--btn bg-blue active"
                        href="/mine-ur-network"
                        target="_blank"
                      >
                        Developer Docs 
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
