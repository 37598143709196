import React from 'react'
import Header from '../Comman/Header'
import Footer from '../Comman/Footer'
import DeveloperReasHero from './DeveloperReasHero'
import GetStarted from './GetStarted'
import StartBuilding from './StartBuilding'
import TelosFooter from '../Telos_net/TelosFooter'

export default function DeveloperResourcers() {
    return (
        <>
            <Header />
            <DeveloperReasHero />
            <GetStarted />
            <StartBuilding/>
            <TelosFooter/>
            {/* <Footer /> */}
        </>
    )
}
