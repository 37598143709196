import React from "react";

export default function Minig() {
  return (
    <>
      <div className="fugu--feature-section fugu--section-padding2 bg-white">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-4 col-md-6">
              <div
                className="fugu--iconbox-wrap fugu--iconbox-wrap3 box-white-wrap4 wow fadeInUpX"
                data-wow-delay="0s"
                style={{
                  visibility: "visible",
                  animationDelay: "0s",
                  animationName: "fadeInUpX",
                }}
              >
                <div className="fugu--iconbox-thumb ">
                  <img
                    src="assets/images/img-one/limited_token_supply.png"
                    width="70"
                    alt=""
                  />
                </div>
                <div className="fugu--iconbox-data">
                  <h4 className="text-black">Technical Support & Resources</h4>
                  <p className="text-black">
                    Providing developers with access to cutting-edge development
                    tools, SDKs, and APIs that simplify the building process on
                    Network1. It includes access to the latest blockchain
                    technologies, comprehensive documentation, and best
                    practices to ensure that developers can focus on creating
                    high-quality dApps.
                  </p>

                </div>
              </div>
            </div>
            <div className="col-xl-4 col-md-6">
              <div
                className="fugu--iconbox-wrap fugu--iconbox-wrap3 box-white-wrap4 wow fadeInUpX"
                data-wow-delay="0s"
                style={{
                  visibility: "visible",
                  animationDelay: "0s",
                  animationName: "fadeInUpX",
                }}
              >
                <div className="fugu--iconbox-thumb">
                  <img
                    src="assets/images/img-one/exclusive_benefits.png"
                    width="60"
                    alt=""
                  />
                </div>
                <div className="fugu--iconbox-data">
                  <h4 className="text-black">Mentorship & Guidance</h4>
                  <p className="text-black">
                    One of the standout features of Network1 Studio is its
                    mentorship program, which connects developers with
                    experienced blockchain experts. These mentors provide
                    valuable insights into the development process, from
                    technical guidance to strategic advice on scaling and
                    marketing, ensuring that projects are set up for long-term
                    success.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-md-6">
              <div
                className="fugu--iconbox-wrap fugu--iconbox-wrap3 box-white-wrap4 wow fadeInUpX"
                data-wow-delay="0s"
                style={{
                  visibility: "visible",
                  animationDelay: "0s",
                  animationName: "fadeInUpX",
                }}
              >
                <div className="fugu--iconbox-thumb">
                  <img
                    src="assets/images/img-one/community_empowerment.png"
                    width="70"
                    alt=""
                  />
                </div>
                <div className="fugu--iconbox-data">
                  <h4 className="text-black">Financial Support and Grants</h4>
                  <p className="text-black">
                    Network1 Studio offers various grants and funding
                    opportunities to promising projects. By providing
                    early-stage financial support, the platform helps developers
                    cover costs associated with building and launching their
                    dApps, allowing them to focus on innovation rather than
                    fundraising.
                  </p> <br />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
