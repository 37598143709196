import React from "react";

export default function WhatWho() {
  return (
    <>
      <div className="fugu--vision-section bg-white py-5">
        <div className="container">
          <div className="row py-5">
            <div className="col-xl-6 col-lg-6 d-flex align-items-center">
              <div className="fugu--default-content content-sm">
                <h3 className="text-black">Empowering Decentralization</h3>
                <p className="text-black">
                  Network1 empowers decentralization with the community
                  governance approach that allows everyone to have their own
                  stance!
                </p>
                <div className="fugu--btn-wrap">
                  <a className="fugu--btn bg-gradient" href="/coming-soon">
                    See Proposals
                  </a>
                </div>
              </div>
            </div>
            <div
              className=" col-xl-6 col-lg-6"
              style={{ backgroundColor: "black" }}
            >
              <img
                src="assets/images/img-one/powering-the-decentralization.png"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
