import React from 'react'
import Header from '../Comman/Header'
import Footer from '../Comman/Footer'
import ProductHero from './ProductHero'
import NetEx from './NetEx'
import NetFX from './NetFX'
import Netter from './Netter'
import NetBox from './NetBox'
import MineUrNetwork from './MineUrNetwork'
import NetMeet from './NetMeet'
import NetPay from './NetPay'
import NetSoft from './NetSoft'
import NetPlay from './NetPlay'
import TelosFooter from '../Telos_net/TelosFooter'

export default function Product() {
    return (
        <>
            <Header />
            <ProductHero />
            <NetEx />
            <NetFX />
            <Netter />
            <NetBox />
            <MineUrNetwork />
            <NetMeet />
            <NetPay />
            <NetSoft />
            <NetPlay />
            <TelosFooter/>
            {/* <Footer /> */}
        </>
    )
}
